import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { MdOutlineNotificationAdd } from 'react-icons/md';

const App = () => {

  return (
    <Container>
      <Row>
        <Col>
          <Hero />
        </Col>
      </Row>
    </Container>
  );
}

const SubscriberForm = ({ setView }) => {

  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);


  useEffect(() => {

    if (email) {

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setValid(true);
      } else {
        setValid(false);
      }

    }

  }, [email]);

  const onChange = (e) => {
    let newEmail = e.target.value.replace(/\s/g, "");
    setEmail(newEmail);
  }

  const subscribe = (e) => {

    if (email && valid) {
      const config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email })
      }
      fetch("https://xsmzx9l237.execute-api.us-east-1.amazonaws.com/hello", config);
      setView('done');
    }

  }

  return (
    <Container>
      <Row>
        <Col className="offset-md-4 col-md-4 mx-auto">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Want to be notified on launch?</Form.Label>
            <Form.Control type="email" placeholder="Enter email address" value={email} onChange={onChange} />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 mb-4">
        <Col className="offset-md-4 col-md-4 mx-auto">
          <Button disabled={!valid} variant="primary" type="button" className="px-4 me-sm-3" size="lg" onClick={subscribe}>
            Register now
          </Button>{' '}<Button variant="outline-secondary" onClick={(e) => setView('about')} type="button" className="px-4 me-sm-3" size="lg">Never mind</Button>
        </Col>
      </Row>
    </Container>
  )

}

const Hero = () => {

  const [view, setView] = useState('about');

  return (
    <div className="px-4 pt-5 my-5 text-center">
      <h1 className="display-4 fw-bold">He's coming.</h1>
      {"form" === view ?
        <SubscriberForm setView={setView} />
        :
        <About view={view} setView={setView} />
      }
      {"form" !== view &&
        <div className="overflow-hidden" style={{ maxHeight: "30vh" }}>
          <div className="container px-5 text-muted">
            From the author of <a href="https://participants1.com" rel="noreferrer" target="_blank">Participants (1)</a>
          </div>
        </div>
      }
    </div>
  )
}

const About = ({ view, setView }) => {

  return (
    <>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          Summer Snowfall <small className="text-muted">(Finder Of Lost Things, Solver of Unsolvable Problems)</small> is struggling to make a living.
        </p>
        <p className="lead mb-4">
          Riddled with gambling debts, unsatisfied with a changing social order
          that has suddenly made him a pariah, he works with his friend Cloudy, the disgruntled owner of Cloudy's High Magic Emporium and Discount Outlet.
        </p>
        <p className="lead mb-4">
          They represent the past, a way of life that has come and gone, and yet together, they're about to embark on a journey so fantastic that generations of
          minstrels will sing about them.
        </p>
        {"about" === view ?
          <>
            <p className="text-warning">The book is coming out soon!</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
              <button onClick={() => setView('form')} type="button" className="btn btn-primary btn-lg px-4 me-sm-3"><MdOutlineNotificationAdd /> Don't miss the launch!</button>
            </div>
          </>
          :
          <>
            <p className="text-warning">Thanks, we'll notify you when it's launch time!</p>
          </>
        }

      </div>
    </>
  )

}

export default App;
